.btn {
  text-transform: uppercase;
  outline: none;
  &.disabled,
  &:disabled {
    opacity: 1;
    color: $white;
    background-color: #bdbdbd;
    border-color: #bdbdbd;
  }
}

.btn-primary {
  &.selected,
  &:hover {
    background-color: $bluedark;
    border-color: $bluedark;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.25);
  }
  &.red {
    background-color: $red;
    border-color: $red;
    &.selected,
    &:hover {
      background-color: $reddark;
      border-color: $reddark;
    }
  }
  &.gray {
    background-color: $gray-600;
    border-color: $gray-600;
    &.selected,
    &:hover {
      background-color: $black;
      border-color: $black;
    }
  }
  &.green {
    background-color: $green;
    border-color: $green;
    &.selected,
    &:hover {
      background-color: $greendark;
      border-color: $greendark;
    }
  }
}

.btn-secondary {
    color: $blue;
    border-color: $blue;
    background-color: $gray-100;
  &.selected,
  &:hover {
    color: $white;
    background-color: $bluedark;
    border-color: $bluedark;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.25);
  }
  &.red {
    color: $red;
    border-color: $red;
    &.selected,
    &:hover {
      color: $white;
      background-color: $reddark;
      border-color: $reddark;
    }
  }
  &.gray {
    color: $gray-600;
    border-color: $gray-600;
    &.selected,
    &:hover {
      color: $white;
      background-color: $black;
      border-color: $black;
    }
  }
  &.green {
    color: $green;
    border-color: $green;
    &.selected,
    &:hover {
      color: $white;
      background-color: $greendark;
      border-color: $greendark;
    }
  }
  &.black {
    color: $black;
    border-color: $black;
    &.selected,
    &:hover {
      color: $white;
      background-color: $black;
      border-color: $black;
    }
  }
  &.rounded-pill {
    padding: 0.83334rem 1.3888rem;
    line-height: 1;
  }
}
