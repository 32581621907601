.tx-cs2-people {



  .grey-text {
    color: #706F6F;
  }

  .people-item {
    box-shadow:  0px 0px 7px rgba(0, 0, 0, 0.17);
   /* min-width: 367px;*/
    min-height: 468px;

    .svg-icon {
      margin-right: 0.5rem;
    }


    .function {
      text-transform: uppercase;
      font-size: 0.78rem;
      line-height: 1.2rem;
    }
    .description {
      font-size: 1rem;
      line-height: 1.55rem;
      font-weight: $font-weight-normal;
    }

    .email {

    }
  }
}
