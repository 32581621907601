.hideme {
  margin-top: calc(var(--meta));
}
.main-header {
  transition: all .35s ease;
  box-shadow: 0px 2px 8px rgba(0,0,0,0.17);
}
.logo-wrap {
  @include media-breakpoint-down(md) {
    box-shadow: 0px 2px 8px rgba(0,0,0,0.17);
    padding: 7px 15px !important;
  }
}

@include media-breakpoint-down(md) {
  .mainNavigation {
    .row {
      margin: 0 -10px;
    }
  }
}
.hamburger {
  @include media-breakpoint-down(md) {
    margin-right: -.75rem;
  }
}

.metaNavigation {
  background-color: $gray-100;
  .navbar {
    padding: 0.5556rem 0 1.3888rem 0;
    @include media-breakpoint-down(md) {
      padding: 0.5556rem 0;
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          line-height: 2.1875rem;
          padding: 0 1.875rem 0 0;
          text-transform: uppercase;
          color: $gray-600;
          font-size: 0.75rem;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.mainNavigation {

  #mainNav {
    font-size: 1.125rem;
    font-weight: 700;
    .nav {
      .nav-item {
        padding: 25px 0;
        .nav-link {
          font-size: 1rem;
          color: $gray-600;
          padding: 0;
          &:hover {
            color: $bluedark;
          }
        }
        .subtrigger {
          padding: 0 1rem;
          font-weight: 400;
          position: relative;
          &:hover {
            color: $bluedark;
          }
        }
        &:hover,
        &.active,
        &.show {
          .nav-link {
            color: $bluedark;
          }
          .subtrigger {
            color: $bluedark;
          }
        }
        //2nd level
        .dropdown-menu {
          margin: 0;
          padding: 0;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
          ul.dropdownmenu {
            overflow-y: auto;
            padding: 0;
            > li {
              display: flex;
              justify-content: space-between;
              padding: 0;
              background: none;
              position: static;
              a {
                padding: 15px 25px;
                text-decoration: none;
                color: $gray-600;
                width: 100%;
                font-size: 0.8889rem;
                &:hover {
                  color: $blue;
                  background-color: #FAFAFA;
                }
              }
              .subtrigger {
                display: flex;
                align-items: center;
                cursor: pointer;
                color: $gray-600;
                padding: 0;
                top: initial;
                &:hover {
                  color: $blue;
                  background-color: #FAFAFA;
                }
                i {
                  padding: 1px 25px 1px 16px;
                  border-left: 1px solid #606060;
                }
              }
              &.active {
                > a,.subtrigger {
                  color: $blue;
                  background-color: #FAFAFA;
                }
              }
              //3rd and next level
              .dropdown-menu.sublevel {
                left: 100%;
                top: 0;
                bottom: initial;
                &.dropdown-menu-right {
                  right: auto;
                  left: 0;
                  transform: translateX(-100%);
                }
              }
            }
          }
        }
      }
    }
  }
  .searchBtn {
    color: $gray-600;
    font-size: 25px;
    padding-right: 15px;
  }
}

#mainMobileNav {
  .mobileMenuWrap {
    max-height: 75vh;
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: -5px;
    margin-right: -5px;
    padding: 0 5px;
  }
  ul.nav {
    margin: 18px 0;
    .nav-item {
      .nav-link {
        color: $gray-600;
        font-size: 1rem;
        font-weight: 700;
        padding: 8px;
      }
      .subtrigger {
        margin-right: 14px;
        i {
          padding: 1px 14px;
          border-left: 1px solid #DCDCDC;
          font-weight: 400;
        }
      }
      ul.sublevels {
        .nav-item {
          margin-left: 10px;
          .nav-link {
            font-size: 0.8889rem;
            font-weight: 400;
          }
          .subtrigger {
            font-size: 14px;
            i {
              padding: 1px 15px;
            }
          }
          &.no-sub {
            padding-right: 14px;
          }
          &.activeCollapse,
          &.active {
            > .subtrigger,
            > .nav-link {
              background-color: #fafafa;
            }
          }
        }
      }
      &.activeCollapse,
      &.active {
        > .subtrigger,
        > .nav-link {
          color: $blue;
        }
      }
      &.activeCollapse {
        > .subtrigger i {
          &:before {
            content: "\f106";
          }
        }
      }
    }
  }
  .mobileMeta {
    background-color: $gray-100;
    margin-right: - $grid-gutter-width * .5;
    margin-left: - $grid-gutter-width * .5;
    .nav {
      padding: 25px 0;
      text-transform: uppercase;
      .nav-item {
        .nav-link {
          font-size: 12px;
          font-weight: 400;
          padding: 0 16px;
        }
      }
    }
  }
}
