label,
.control-label {
  font-weight: $font-weight-bold;
  color: #212529;
  margin-bottom: 0.27778rem;
}
.dsgvo-label {
  color: #212529 !important;
}
.custom-control-input,
.form-control {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: none;
}

.custom-file-label {
  cursor: pointer;
  &:after {
    background: $blue;
    color: $white;
    font-family: $font-family-base;
    font-size: 0.6666rem;
    font-weight: normal;
    cursor: pointer;
    line-height: 35px;
    padding: 2px 25px;
    height: auto;
  }
}
.custom-file-input {
  min-height: 37px;
  height: 100%;
}
.custom-select:focus,
.custom-file-input:focus ~ .custom-file-label {
  box-shadow: none;
  outline: none;
}
.custom-select {
  padding-right: 2.75rem;
}
.has-error {
  .form-label {
    color: $red;
  }
  .form-border-bottom,
  .hf-invalid,
  .error {
    border-color: $red;
  }
  .hs-input,
  .form-control {
    border-color: $red;
    &:focus {
      border-color: $lightblue;
    }
  }
  .hf-warning,
  .invalid-feedback {
    display: block;
    color: $red;
    font-size: .8125rem;
    padding: 0;
    margin-top: .25rem;
  }
}

.bootstrap-select {
  width: 100% !important;
  transition: none !important;
  .dropdown-toggle {
    transition: none !important;
  }
  &.show {
    .dropdown-toggle {
      box-shadow: none !important;
      z-index: 1001;
      &:after {
        transform: rotate(-180deg);
      }
    }
    .inner {
      display: block;
    }
  }
  .dropdown-menu {
    padding: 0;
  }
}
.bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
  transition: none !important;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:active, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
  color: $gray-900;
}
.form-group {
  .custom-radio,
  .custom-checkbox {
    padding-bottom: 10px;
    &:last-child {
      padding-bottom: 0;
    }
    .custom-control-label {
      font-weight: 400;
      &:before {
        width: 16px;
        height: 16px;
        border: 2px solid $blue;
        box-shadow: none;
        border-radius: 0;
      }
      &:after {
        width: 16px;
        height: 16px;
      }
    }
  }
  .custom-radio {
    .custom-control-label {
      &:before {
        border-radius: 50%;
      }
    }
  }
}
.has-error {
  .error.help-block {
    color: $red;
    padding-top: 7px;
    font-size: 0.7222rem;
  }
}

.actions {
  .form-navigation {
    .btn-toolbar {
      @include media-breakpoint-down(sm) {

        .btn-group {
          width: 100%;

          .submit {
            width: 100%;

            button.btn  {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
