.keyvisual {
  .slider-info {
    position: absolute;
    top: 50%;
    left: 8.3334rem;
    transform: translateY(-50%);
    text-shadow: 0px 0px 15px $black;
    @include media-breakpoint-down(sm) {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      max-width: 100%;
      text-shadow: none;
      padding: 15px;
      background-color: $white;
    }
    @include media-breakpoint-up(md) {
      h3,p {
        color: $white;
      }
      h3 {
        font-size: 2.5rem;
      }
      p {
        font-weight: 900;
        text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25), 0px 0px 5px #000000;
      }
    }
  }
  .swiper-slide:not(.swiper-slide-active){
    opacity: 0 !important;
  }
  .h-keyvisual {
    position:relative;
    @include media-breakpoint-up(md) {
      height: 500px;
    }
  }
  @include media-breakpoint-down(md) {
    .keyvisualslider {
      .wattermark {
        font-size: 11px;
        .svg-icon {
          height: 11px;
          width: 11px;
          margin-bottom: 2px;
        }
      }
    }
  }
}

.swiper-pagination-bullets {
  bottom: 35px !important;
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #999999;
    margin: 0 5px !important;
    opacity: 1;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.17);
    &-active {
      width: 15px;
      height: 15px;
      background-color: $white;
    }
  }
}
.swiper-button-prev{
  left: 52px;
  right: auto;
}
.swiper-button-next {
  right: 52px;
  left: auto;
}
.swiper-button-prev,
.swiper-button-next {
  color: $white;
  font-weight: 700;
  text-shadow: 0px 0px 15px $black;
}
.scroll-me {
  position: absolute;
  bottom: 35px;
  right: 40px;
  z-index: 20;
  cursor: pointer;
  .svg-icon {
    width: 36px;
    height: 36px;
    color: $white;
    --color1: #FFFFFF;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.17));
  }
}
@include media-breakpoint-down(sm) {
  .swiper-pagination-bullets {
    top: 225px;
    bottom: auto !important;
  }
  .scroll-me {
    top: 210px;
    right: 15px;
    bottom: auto;
  }
}
@include media-breakpoint-down(xs) {
  .swiper-pagination-bullets {
    top: 160px;
    bottom: auto !important;
  }
  .scroll-me {
    top: 145px;
    right: 15px;
    bottom: auto;
  }
}
