//.fade { visibility: hidden; transition: visibility $animation-base, opacity $animation-base; }
//.fade.in { visibility: visible; }
$bluedakrblue: linear-gradient(68.19deg, #0E77BD 6.43%, #062E48 82.53%);
$bluedakrblue180: linear-gradient(180deg, #0E77BD 6.43%, #062E48 82.53%);
$blueradial: radial-gradient(67.66% 549.35% at 50% 40%, #0070BA 0%, #002A45 100%);
$blueradialbig: radial-gradient(118.43% 44.75% at 43.78% 19.48%, #0070BA 0%, #002A45 128%);
$shadowbase: 0px 4px 4px rgba(20, 76, 114, 0.25);
$shadowteaser: 2px 4px 8px rgba(0, 0, 0, 0.12);
$shadowteaserhover: 4px 4px 10px rgba(0, 0, 0, 0.25);
$shadowbottom: 2px 2px 3px rgba(0, 0, 0, 0.12);
$shadowtext: 0px 4px 4px rgba(0, 0, 0, 0.5), 5px 5px 10px rgba(0, 0, 0, 0.17);
$shadowinner: inset 5px 5px 50px rgba(0, 0, 0, 0.7);
$linebg: repeating-linear-gradient( 45deg, rgba(255,255,255,.06), rgba(255,255,255,.06) 1px, rgba(255,255,255,0) 2px, rgba(255,255,255,0) 7px );
$border-radius-box: 2px !default;
$text-65: rgba(0,0,0,.65);
$font-size-xs: 0.8125rem;

:root {
  --fancybox-bg: rgba(0,0,0,.8);
}

.frame-space-before-extra-small { padding-top: 20px !important; }
.frame-space-before-small { padding-top: 80px !important; }
.frame-space-before-medium { padding-top: 100px !important; }
.frame-space-before-large { padding-top: 140px !important; }
.frame-space-before-extra-large { padding-top: 180px !important; }
.frame-space-after-extra-small { padding-bottom: 20px !important; }
.frame-space-after-small { padding-bottom: 80px !important; }
.frame-space-after-medium { padding-bottom: 100px !important; }
.frame-space-after-large { padding-bottom: 140px !important; }
.frame-space-after-extra-large { padding-bottom: 180px !important; }

@include media-breakpoint-down(md) {
  .frame-space-before-extra-small { padding-top: 15px !important; }
  .frame-space-before-small { padding-top: 40px !important; }
  .frame-space-before-medium { padding-top: 50px !important; }
  .frame-space-before-large { padding-top: 80px !important; }
  .frame-space-before-extra-large { padding-top: 120px !important; }
  .frame-space-after-extra-small { padding-bottom: 15px !important; }
  .frame-space-after-small { padding-bottom: 40px !important; }
  .frame-space-after-medium { padding-bottom: 50px !important; }
  .frame-space-after-large { padding-bottom: 80px !important; }
  .frame-space-after-extra-large { padding-bottom: 120px !important; }
}

html {
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  font-size: 18px;
}
body {
  overflow-x: hidden;
  max-width: 100vw;
  padding-top: calc(88px - var(--meta,0));
  transition: all .35s ease;
  @include media-breakpoint-down(md) {
    padding-top: calc(73px - var(--meta,0));
  }
}
body.overflow-hidden {
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
}
// content wrapper
.content-wrapper {
  padding: 0 $grid-gutter-width/2;
  max-width: $max-width-content-wide;
  margin: 0 auto;
  @include media-breakpoint-down(md) {
    padding: 0 $grid-gutter-width-mobile/2;
  }
}
.element-wrapper { margin-bottom: $spacer*2}

.navigation-wrap {
  max-width: $max-width-content-wide;
  margin: 0 auto;
  padding: 0 $grid-gutter-width/2;
  width: 100%;
}

.narrow-wrapper {
  max-width: $max-width-content-narrow;
  margin: 0 auto;
  padding: 0 $grid-gutter-width/2;
  width: 100%;
  @include media-breakpoint-down(md) {
    padding: 0 $grid-gutter-width-mobile/2;
  }
}

.container-extended-gray,
.container-narrow-gray{
  background: $gray-300;
}

.container-narrow-gray,
.container-narrow-white {
  > .content-fluid {
    padding: 0 $grid-gutter-width/2;
    margin: 0 auto;
    max-width: $max-width-content-narrow;
    @include media-breakpoint-down(md) {
      padding: 0 $grid-gutter-width-mobile/2;
    }
  }
}

.container-extended-gray,
.container-extended-white {
  > .content-fluid {
    padding: 0 $grid-gutter-width/2;
    margin: 0 auto;
    max-width: $max-width-content-wide;
    @include media-breakpoint-down(md) {
      padding: 0 $grid-gutter-width-mobile/2;
    }
  }
}

@include media-breakpoint-down(md) {
  .row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .row > .col,
  .row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

h1,.h1 {
  color: $blue;
}

h4, .h4 {
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
}

@include media-breakpoint-down(md) {
  h1,.h1 {
    font-size: 36px;
  }
  h2,.h2 {
    font-size: 30px;
  }
  h3,.h3 {
    font-size: 22px;
  }
  h4,.h4 {
    font-size: 17px;
  }
  .btn {
    font-size: 12px;
    padding: 0 25px;
    line-height: 35px;
  }
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 15px;
  }
}

.w-lg-auto {
  @include media-breakpoint-up(lg) {
    width: auto !important;
  }
}

.bg-grey {
  background-color: $gray-300;
}

.lead {
  font-size: 1.05556rem;
  line-height: 1.525;
  font-weight: 700;
}
.frame-type-header {
  .lead {
    margin-bottom: 2.2223rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
    }
  }
}

ol,
ul {
  padding-left: 20px;
}

em {
  font-size: 0.83333rem;
}



.svg-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  &.blue {
    color: $blue;
    --color1: #185FA5;
  }
  &.red {
    color: $red;
    --color1: #E30613;
  }
  &.green {
    color: $green;
    --color1: #84BE41;
  }

  &.black {
    color: $black;
    --color1: #000;
  }
  &.white {
    color: $white;
    --color1: #FFF;
  }
}
header {
  .svg-icon {
    width: 2.7778rem;
    height: 2.7778rem;
    margin-bottom: 1.1112rem;
  }
}
table {
  th {
    color: $bluedark;
  }
  th,td {
    background-color: $white;
  }
  thead {
    th {
      border: 0;
      color: $gray-600 !important;
      vertical-align: top;
    }
  }
}

.ce-uploads {
  li {
    padding: 8px 0;
    border-bottom: 1px solid #CFCFCF;
    .ce-uploads-fileName {
      font-size: $font-size-base;
      padding-left: 9px;
    }
    i {
      color: $gray-600;
    }
  }
}

.overlay- {
  &20,
  &30,
  &60 {
    &:after {
      content: '\A';
      position: absolute;
      width: 100%;
      height:100%;
      top:0;
      right: 0;
      bottom: 0;
      left:0;
      transition: all .35s linear;
    }
  }
  &20:after {
    background:rgba(0,0,0,0.2);
  }
  &30:after {
    background:rgba(0,0,0,0.3);
  }
  &60:after {
    background:rgba(0,0,0,0.6);
  }
}

.breadcrumb {
  margin-bottom: 0;
  padding: 0.4444rem 0 1.2223rem 0;
  @include media-breakpoint-down(md) {
    padding: 0.7222rem 0;
  }
  .breadcrumb-item {
    padding: 0;
    &:before {
      content: none;
    }
    .svg-icon {
      width: 1.1112rem;
      height: 1.1112rem;
      top: -2px;
      position: relative;
    }
    a {
      font-size: 0.7222rem;
      color: $gray-600;
      line-height: 1;
      &:after {
        display: inline-block;
        content: "";
        font-family: "Font Awesome 5 Pro";
        font-weight: 400;
        background-repeat: no-repeat;
        background-position: center;
        padding: 0 8px;
        font-size: .6rem;
        color: #999999;
      }
    }
    &:last-child {
      a:after {
        content: none;
      }
    }
  }
}

.wattermark {
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: white;
  --color1: white;
  font-size: 17px;
  text-shadow: 0 0 15px #000000;
  .svg-icon {
    height: 17px;
    width: 17px;
    filter: drop-shadow(0 0 15px #000000);
    margin-bottom: 3px;
  }
}
