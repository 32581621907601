.tx-cs2-blog {
  .blog_list,
  .blog_moreblogs,
  .blog_teaser {
    a {
      &:last-child {
        .blog_item {
          margin-bottom: 0;
        }
      }
    }
    .blog_item {
      margin-bottom: 1.1112rem;
      box-shadow: $box-shadow;
      border: 0;
      .card-body {
        padding: 0.5556rem;
        .date {
          color: #706F6F;
          padding: 0.5rem 0 1.1112rem 0;
          font-size: 1rem;
        }
        h4 {
          margin-bottom: 1.1112rem;
        }
        p {
          color: $gray-600;
          font-size: 1rem;
        }
      }
      &:hover {
        box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.25);
      }
    }
    .swiper-slide {
      box-shadow: $box-shadow;
      .blog_item {
        box-shadow: none;
      }
    }
  }
  .categories {
    bottom: 20px;
    left: 0;
    max-width: 25%;
    @include media-breakpoint-down(md) {
      bottom: 15px;
      max-width: 75%;
    }
    .cat {
      margin-top: 10px;
      padding: 5px 7px;
      text-transform: uppercase;
      color: $white;
      line-height: 1;
      display: inline-block;
      @include media-breakpoint-down(md) {
        margin-top: 5px;
      }
    }
  }
  &.detail {
    .datecat {
      margin-top: 1.11112rem;
      margin-bottom: 3.33335rem;
      @include media-breakpoint-down(md) {
        margin-top: 1.5rem;
        margin-bottom: 1.2777rem;
      }
      .categories {
        max-width: 100%;
        .cat {
          font-size: 0.83334rem;
          margin-right: 5px !important;
          width: 150px;
          text-align: center;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .date {
        color: #706F6F;
      }
      .back-btn a{
        color: $gray-600;
        font-size: 0.7222rem;
        i {
          padding-right: 8px;
        }
      }
    }
    .lead {
      margin-bottom: 1.4444rem;
    }
    .blog-text {
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.blog-list {
    .filter {
      .btn {
        margin: 8px;
        @include media-breakpoint-down(md) {
          margin: 5px 10px 5px 0;
          padding: 10px 15px;
        }
      }
    }
  }
  &.header-image {
    .blog-detail-height {
      position: relative;
      height: 500px;
      @include media-breakpoint-down(sm) {
        height: 259px;
      }
      @include media-breakpoint-down(xs) {
        height: 194px;
      }
    }
  }
}

.cs2blog_list {
  .lead {
    margin-bottom: 4.4445rem;
  }
}
