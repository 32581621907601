#tx-solr-search {
  .tx-solr-search-form {
    .form-control {
      height: initial;
      @include media-breakpoint-down(md) {
        line-height: 1;
      }
    }
  }
  .result-content {
    .results-highlight {
      font-weight: bold;
      color: $black;
    }
  }
}

#searchOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.5s ease-in-out;
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;

  &.open {
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
    z-index: 2000;
  }
  .tx_solr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    color: $black;
    outline: none;
    border-bottom: 1px solid $gray-600;
    @include media-breakpoint-down(md) {
      width: 95%;
    }
    input {
      border: 0;
      box-shadow: none;
    }
    .input-group-btn {
      border: 0;
      .btn {
        border-bottom-width: 0;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .close {
    position: fixed;
    top: 15px;
    right: 15px;
  }
}

.tx-solr-autosuggest { border: 1px solid $gray-400; background: $white; overflow: auto; box-shadow: $box-shadow; a { text-decoration: none; } }
.tx-solr-autosuggest strong { font-weight: bolder; color: $blue; }
.tx-solr-autosuggest .autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; cursor: pointer; }
.tx-solr-autosuggest .autocomplete-selected { background: $gray-200; }
.tx-solr-autosuggest .autocomplete-group { padding: 10px 5px; }
.tx-solr-autosuggest .autocomplete-group strong { display: block; border-bottom: 1px solid $gray-700; }
