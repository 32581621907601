.pic-gallery {
  .h-gal {
    height: 355px;
    @include media-breakpoint-down(sm) {
      height: 135px;
    }
  }
  .col-12 {
    .h-gal {
      @include media-breakpoint-down(sm) {
        height: 205px;
      }
    }
  }
  .col {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
    @include media-breakpoint-down(md) {
      padding-left: 4px;
      padding-right: 4px;
      padding-bottom: 8px;
    }
  }
}
.imagefancybox {
  position: relative;
  cursor: pointer;
  .overlay {
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(24, 95, 165, 0.8);
    text-align: center;
    transition: all .35s ease;
    i {
      color: $white;
      font-size: 40px;
      opacity: 0;
    }
  }
  &:hover {
    .overlay {
      width: 100%;
      i {
        opacity: 1;
      }
    }
  }
}
figure {
  figcaption {
    margin-top: 10px;
    color: #706F6F;
  }
}
.fancybox__container {
  .fancybox__carousel {
    .fancybox__viewport {
      .fancybox__slide {
        .fancybox__content {

        }
        .fancybox__caption {
          position: relative;
          background-color: $blue;
          padding: 20px 0;
          text-align: center;
        }
      }
    }
  }
}
