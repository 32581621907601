.tx-cs2-teaser {
  .teaser {
    background-color: $white;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
    .teaser-text {
      padding: 0 15px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      h4,p {
        text-shadow: 0px 0px 15px $black;
      }
      @include media-breakpoint-down(sm) {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        max-width: 100%;
        padding: 20px 30px;
        text-align: left;
        h4,p {
          text-shadow: none;
        }
      }
      @include media-breakpoint-up(md) {
        h4,p {
          color: $white;
        }
      }
    }
    &:hover {
      .overlay-30{
        &:after {
          background: rgba(0,0,0,.6);
        }
      }
    }
  }
  .swiper-button-prev {
    left: -5rem;
  }
  .swiper-button-next {
    right: -5rem;
  }
  .swiper-button-disabled {
    display: none;
  }
  .teaserslider {
    .swiper-slide {
      transition: opacity 0.3s;
      &:not(.visible-slide) {
        opacity: 0.3;
      }
    }
  }
}
