.tx-cs2-accordion {
  .card {
    border:unset;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
  }

  .card>.card-header {
    background-color: unset;
    border:unset;
    padding: 0.75rem 0.6rem;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      padding: 0.3rem 0.3rem;
    }

    .title_box {
      padding-right :1.6rem;
      @include media-breakpoint-down(sm) {
        padding-right :1.2rem;
      }
    }

    .icon-box {
      right: 0;
      @include media-breakpoint-down(sm) {
        top: -0.3rem;
      }
      .svg-icon {
        width: 1.4rem;
        height: 1.4rem;
        @include media-breakpoint-down(sm) {
          width: 1rem;
          height: 1rem;
        }
      }
    }
    .closed {
      display: none;
    }
    .opened {
      display: inline-block;
    }
    .collapsed {
      .closed {
        display: inline-block;
      }
      .opened {
        display: none;
      }
    }

  }

  .card-body {
    padding: 0.6rem;
    @include media-breakpoint-down(sm) {
      padding: 0.3rem;
    }


    .img-fluid {
      width: 100%;
      height: auto;
      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }
    }

  }
}
