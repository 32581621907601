.main-footer {
  padding: 0;
  background: $white;
  .mainFooter {
    margin: 2.5rem 0;
    @include media-breakpoint-down(md) {
      margin: 15px 0;
    }
    .social {
      .nav-link {
        margin-right: 1.6667rem;
        i {
          font-size: 2.5rem;
          color: $blue;
        }
      }
    }
    .contactinfo {
      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }
    }
  }
  #footerbar {
    background: $blue;
    padding: 0.83334rem 0;
    nav {
      a {
        color: $white;
        font-size: 0.83334rem;
        font-weight: 400;
        padding: 0 0.7rem;
      }
    }
    .copyright {
      font-size: 0.83334rem;
      color: $white;
      padding: 0 0.7rem;
    }
  }
}

#mobilefooterbar {
  background-color: $bluedark;
  .copyright {
    font-size: 15px;
    padding: 15px 0;
  }
}
