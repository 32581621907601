.tx-cs2-events {
  .btn.btn-secondary.rounded-pill {
    font-size: 0.7222rem;
    line-height: 0.7222rem;

    @include media-breakpoint-down(md) {
      padding: 0.56rem 0.83rem;
      margin-bottom:  0.56rem;
    }
  }

  .event-filter {
    .category {
      margin-right: .8rem;
      position: relative;
      @include media-breakpoint-down(md) {
        margin-right: .6rem;
      }
      &.selected {
        color: $white;
        background-color: $green;
        border-color: $green;
      }
      i {
        position: absolute;
        top: 35%;
        right: 5px;
        transform: translateY(-35%);
      }
    }

    .date-part {
      margin-left: 3rem;
    }



    .date-field {
      position: relative;
      width: 170px;
      margin-right: 5px;
      display: inline-block;
      @include media-breakpoint-down(md) {
        margin-top: 2.5rem;
        width: 100%;
        display: block;
      }

      .form-control {
        height: 37px;
        display: inline;
        width: 100%;
      }

      .date-label {
        position: absolute;
        top: -1.95rem;
        font-weight: 700;
      }

      .icon-wrap {
        position: absolute;
        right: 0;
        top: 0;
        width: 37px;
        height: 37px;
        background-color: $blue;
        z-index: 50;

        .svg-icon {
          width: 1.5rem;
          height: 1.38rem;
          margin-left: 5px;
          margin-top: 6px;

        }
      }
    }
  }

  a {
    color: $black;
    &:hover {
      text-decoration: none;
    }

    .card {
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);

      .image-part {
        position: relative;

        .itemDate {
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: $blue;
          color: $white;
          padding: 0.3rem 0.4rem;
          font-size: 0.833rem;
          line-height: 1.111rem;
          font-weight: 700;
          @include media-breakpoint-down(md) {
            font-size: 0.722rem;
            line-height: 0.944rem;
          }
        }
      }



      .itemCategory {
        color: #706F6F;
        font-size: 1rem;
        line-height: 1.5rem;
        margin-top: 1.11rem;
        @include media-breakpoint-down(md) {
          margin-top: 0.833rem;
        }
      }
      .title {

        margin-top: 1.11rem;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
          margin-top: 0.833rem;
        }
      }
      .teaser {
        font-size: 1rem;
        line-height:1.5rem;
        margin-top: 1.11rem;
        @include media-breakpoint-down(md) {
          margin-top: 0.833rem;
        }
      }
    }


    .teaser {
      color: $gray-600;
    }
  }

  .h-event {
    @include media-breakpoint-down(md) {
      height: 160px;
    }
  }
}
